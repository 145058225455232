import React from 'react';
import { Box, Typography, Container, Grid, Link } from '@mui/material';

function Contact() {
  return (
    <Box id="contact" sx={{ padding: '40px 0' }}>
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom>
          Contact Information
        </Typography>
        <Grid container spacing={4} alignItems="stretch">
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'center',
              }}
            >
              <img
                src="/images/olga_vechtomova.jpg"
                alt="Olga Vechtomova"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%', // Ensures the image doesn't stretch more than the available space
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Olga Vechtomova
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Professor
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Department of Management Science and Engineering, Faculty of Engineering
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Cheriton School of Computer Science (cross-appointment)
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              University of Waterloo
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              200 University Avenue West
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Waterloo, Ontario, Canada, N2L 3G1
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Email: <Link href="mailto:ovechtom@uwaterloo.ca">ovechtom@uwaterloo.ca</Link>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Phone: +1 519 888 4567 ext. 32675
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              Room: CPH 3631
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Contact;
